.gallery {
  &__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
    grid-gap: 6rem;
    width: 90%;
    margin: 0 auto;

    @include respond(lap-top) {
      grid-gap: 4rem;
      width: 80%;
    }
    @include respond(phone-big) {
      width: 95%;
    }
  }
  &__heading {
    display: grid;
    justify-items: center;
  }

  &__card {
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4rem 5rem rgba($color-primary, 0.1);
    padding: 2rem;
    border-radius: $default-border-radius;

    &__heading {
      transform: translateY(-50%);
      display: block;
      font-size: 2rem;
      padding: 1rem 2rem;
      background-color: $color-primary;
      border-radius: $default-border-radius;
      margin: 0 auto;
      text-align: center;
      width: 80%;
      align-self: center;
    }
    &__text {
      text-indent: 2rem;
      font-size: 1.6rem;
      @include respond(phone) {
        font-size: 1.5rem;
      }
    }
    &__logo:link,
    &__logo:active {
      position: absolute;
      top: 3rem;
      left: 3rem;
      display: inline-block;
      text-decoration: none;
      color: $color-primary-medium;
      transition: all 0.3s;

      @include respond(phone) {
        top: 4rem;
        left: 4rem;
      }
    }
    &__logo:hover {
      transform: scale(1.05);
      color: $color-primary;
    }
    &__logo:active {
      transform: scale(1.02);
      color: $color-primary-dark;
    }
    &__logo--icon {
      height: 5rem;
      width: 5rem;
      fill: $color-primary-dark;
    }
  }
}

.mySwiper2 {
  width: 100%;
  // height: 45rem;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 2px;
    border-radius: $default-border-radius;
  }
}
.swiper-slide {
  height: 45rem;
}
