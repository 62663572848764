.section {
  transition: transform 1s, opacity 1s;
}

.section--hidden {
  opacity: 0;
  transform: translateY(8rem);
}

.section-about {
  padding: 0 4rem 10rem 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  align-items: center;
  background-color: $color-grey-light-1;
  border-bottom-right-radius: $default-border-radius;
  border-bottom-left-radius: $default-border-radius;
  max-width: 100vw;

  @include respond(tab-port) {
    padding: 0 0rem 4rem 0rem;
  }
  @include respond(phone-big) {
    grid-template-columns: 1.5fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
  }
  @include respond(phone) {
    grid-template-columns: 1fr;
  }
}
.section-description {
  padding: 10rem 2rem 4rem 2rem;
  justify-self: center;
  grid-row-gap: 4rem;

  display: grid;
  grid-template-columns: 1fr;
  max-width: 80vw;

  @include respond(tab-port) {
    padding: 6rem 2rem 4rem 2rem;
    max-width: 90vw;
  }

  &__h2 {
    background-color: $color-grey-light-1;
    padding: 1rem 2rem;
    border-radius: $default-border-radius;
    justify-self: center;
    text-align: center;
    height: max-content;
    // align-self: end;
  }
}

.section-how {
  grid-row: 2/3;
  padding: 4rem 2rem 10rem 2rem;
  display: grid;
  justify-self: center;
  max-width: 80vw;

  @include respond(tab-port) {
    max-width: 90vw;
  }
  @include respond(phone-big) {
    padding: 4rem 2rem 6rem 2rem;
    max-width: 100vw;
  }
}
.section-gallery {
  padding: 10rem 4rem;
  background-color: $color-grey-light-1;
  max-width: 98vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  // width: 90vw;

  @include respond(phone-big) {
    padding: 6rem 0rem;
  }
}

.section-featured-in {
  padding: 4rem 0;
  max-width: 98vw;
}
.section-cta {
  padding: 10rem;
  justify-self: center;
  max-width: 98vw;

  @include respond(phone-big) {
    padding: 4rem;
  }
}

.footer {
  padding: 10rem 4rem 2rem 4rem;
  border-top: 1px solid $color-grey-light-2;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 98vw;

  @include respond(phone-big) {
    padding: 6rem 4rem 2rem 4rem;
    justify-content: center;
    align-items: center;
  }
  @include respond(phone) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include respond(phone-small) {
    grid-template-columns: 1fr;
  }
}
