.about {
  &__images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    border-radius: $default-border-radius;
    margin: 2rem;
    grid-gap: 1rem;

    @include respond(phone) {
      width: 90%;
      justify-self: center;
      margin: 0;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;

    box-shadow: 0 1.5rem 4rem rgba($color-primary-text-dark, 0.4);
    border-radius: $default-border-radius;
    z-index: 10;
    transition: all 0.2s;

    &--1 {
      animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation-delay: 0.2s;
    }
    &--2 {
      animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation-delay: 0.2s;
    }
    &--3 {
      grid-column: 2/3;
      grid-row: 1/3;
      animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    &:hover {
      transform: scale(1.05) translateY(-0.5rem);
      box-shadow: 0 2.5rem 4rem rgba($color-primary-text-dark, 0.5);
      z-index: 20;
    }
  }

  &:hover &__img:not(:hover) {
    transform: scale(0.95);
  }
}
