@import 'abstracts/mixins';
@import 'abstracts/variables';

@import 'base/animations';
@import 'base/base';
@import 'base/typography';
@import 'base/utilities';

@import 'components/button';
@import 'components/composition';
@import 'components/card';
@import 'components/stickynav';
@import 'components/popup';
@import 'components/mobilenav';
// @import 'components/slider';
@import 'components/review';

@import 'layout/footer';
@import 'layout/grid';
@import 'layout/header';
@import 'layout/about';
@import 'layout/description';
@import 'layout/testimonials';
@import 'layout/how';
@import 'layout/gallery';
@import 'layout/featured-in';
@import 'layout/cta';

@import 'pages/home';
@import 'pages/success';
