.sticky .header {
  justify-self: center;

  padding: 0;
  border-radius: none;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 8rem;
  background-color: rgba($color-grey-light-1, 0.97);
  z-index: 999;
  box-shadow: 0 1.2rem 3.2rem rgba($color-primary-text-dark, 0.03);
  border-radius: $default-border-radius;
}

.sticky .section-about {
  margin-top: 8rem;
}
