.featured-in {
  &__box {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h4 {
      font-size: 3rem;
      align-self: center;
      margin-bottom: 4rem;
      color: $color-primary-light;
      text-align: center;

      @include respond(phone) {
        font-size: 2rem;
        margin-bottom: 2rem;
      }
    }
  }

  &__logos {
    display: flex;
    justify-content: space-around;

    @include respond(phone-small) {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(2, 1fr);
      justify-items: center;
      grid-gap: 2rem;
    }

    img {
      height: 4rem;
      filter: brightness(0);
      opacity: 50%;
      transition: all 0.3s;

      @include respond(phone-big) {
        height: 3rem;
      }
      @include respond(phone) {
        height: 2.8rem;
      }

      &:hover {
        filter: brightness(100%);
        opacity: 100%;
        transform: scale(1.1);
      }
    }
  }
  &__logo {
    &-1 {
      @include respond(phone-small) {
        grid-column: 1/3;
      }
    }
    &-2 {
      @include respond(phone-small) {
        grid-column: 3/5;
      }
    }
    &-3 {
      @include respond(phone-small) {
        grid-column: 5/7;
      }
    }
    &-4 {
      @include respond(phone-small) {
        grid-column: 1/4;
      }
    }
    &-5 {
      @include respond(phone-small) {
        grid-column: 4/7;
      }
    }
  }
}
