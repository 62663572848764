.btn {
  &,
  &:link,
  &:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 0.8em 2rem;
    display: inline-block;
    border-radius: 10rem;
    font-size: $default-font-size;
    font-weight: 600;
    font-family: inherit;

    border: none;
    cursor: pointer;
    transition: all 0.3s;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba($color-primary-text-dark, 0.2);
  }

  &:active {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba($color-primary-text-dark, 0.2);
  }
  &:focus {
    outline: none;
  }
}

.btn--full {
  background-image: linear-gradient(
    to right,
    $color-primary-medium,
    $color-primary
  );
  color: $color-grey-light-1;
}

.btn--outline {
  width: max-content;
  background-image: linear-gradient(
    to right,
    $color-grey-light-1,
    $color-grey-light-3
  );
  color: $color-primary-dark;
}

.btn__gallery {
  &__box {
    margin-top: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      display: inline-block;
    }
  }
}

.btn__form {
  background-color: $color-primary-secondary;
  color: $color-grey-light-4;
  padding: 1.4rem;
  width: 100%;

  &:hover {
    background-color: $color-grey-light-5;
    color: $color-primary-secondary;
  }
  @include respond(phone-big) {
    // width: max-content;
  }
}
.btn--review {
  margin: 0 auto;
  // grid-column: 1 / 3;

  @include respond(phone-big) {
    grid-column: auto;
  }
}

.btn--close-popup {
  font-family: inherit;
  color: $color-primary-text-medium;
  position: absolute;
  top: 0.5rem;
  right: 2rem;
  font-size: 4rem;
  cursor: pointer;
  border: none;
  background: none;
}
.btn--close-review {
  font-family: inherit;
  color: $color-grey-light-1;
  position: absolute;
  top: 0.5rem;
  right: 2rem;
  font-size: 4rem;
  cursor: pointer;
  border: none;
  background: none;
}
