.container {
  display: grid;
  grid-template-rows:
    minmax(min-content, calc(100vh - 8rem))
    repeat(3, max-content);

  @include respond(tab-port) {
    grid-template-rows:
      minmax(min-content, calc(100vh - 6rem))
      repeat(3, max-content);
  }
}
