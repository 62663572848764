.success {
  height: 100vh;

  position: relative;
  &-box {
    z-index: 9999;
    @include absCenter;
    width: 50rem;
    background-color: $color-primary-secondary;
    border-radius: $default-border-radius;
    padding: 4rem;
    display: flex;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include respond(phone-small) {
      width: 80vw;
    }

    &--icon {
      height: 12rem;
      width: 12rem;
      fill: $color-primary-text-light;
      // font-size: 10rem;
      // color: $color-primary-text-light;
    }

    &__text {
      color: $color-primary-text-light;
      font-size: 1.8rem;
    }
  }
}
