@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@mixin animationImage {
  transform: scale(1.05);
  box-shadow: 0rem 2rem 4rem rgba($color-primary-text-dark, 0.3);
  overflow: hidden;
}

// MEDIA QUERY MANAGER
/*
1em = 16px
*/
@mixin respond($breakpoint) {
  @if $breakpoint == phone-small {
    @media only screen and (max-width: 28.125em) {
      @content;
    } //450px
  }
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  }
  @if $breakpoint == phone-big {
    @media only screen and (max-width: 56.25em) {
      @content;
    } //900px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 62.5em) {
      @content;
    } //1000px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 81.25em) {
      @content;
    } //1300px
  }
  @if $breakpoint == lap-top {
    @media only screen and (max-width: 93.75em) {
      @content;
    } //1500px
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } //1800
  }
}
