.mobile__nav {
  display: none;
}

.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;
  display: none;
}

.icon-mobile-nav {
  height: 4rem;
  width: 4rem;
  fill: $color-primary;
  // font-size: 4rem;
  margin: 1rem;
  border-radius: 50%;
  transition: all 0.3s;
  // color: $color-primary;
}

#close-outline {
  display: none;
}

@include respond(tab-port) {
  /* MOBILE NAVIGATION */

  .header__nav {
    display: none;
  }

  .btn-mobile-nav {
    display: block;
    z-index: 9999;
  }

  .mobile__nav {
    background-color: rgba(255, 255, 255, 0.7);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    // transform: translateX(100%%);

    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in;

    /* Hide navigation */
    /* Allows NO transitions at all */
    /* display: none; */

    /* 1) Hide it visually */
    opacity: 0;

    /* 2) Make it unaccessible to mouse and keyboard */
    pointer-events: none;

    /* 3) Hide it from screen readers */
    visibility: hidden;
  }

  .nav-open {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
    z-index: 1000;
  }

  .nav-open #close-outline {
    display: block;
  }

  .nav-open #menu-outline {
    display: none;
  }

  .mobile__nav--list {
    color: $color-primary-text-dark;

    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
  }

  .mobile__nav--link:link,
  .mobile__nav--link:visited {
    color: $color-primary-text-dark;
    padding: 1rem;
    text-decoration: none;
    border-radius: 2rem;
    border: 2px solid transparent;
    font-size: 2.4rem;
    text-transform: uppercase;
    font-weight: 700;
  }
  .mobile__nav--link:active,
  .mobile__nav--link:hover {
    box-shadow: 0 1rem 2rem rgba($color-primary-text-dark, 0.3);
    border: 2px solid $color-primary;
  }
}
