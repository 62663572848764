.swiper {
  border-radius: $default-border-radius;
  background-color: $color-grey-light-1;

  width: 100%;
  height: 100%;
  box-shadow: 0 2rem 4rem rgba($color-primary-text-medium, 0.3);
  overflow: hidden;
  border: 2px solid $color-primary;
}

.testimonials {
  &__h3 {
    margin-bottom: 4rem;
  }
  &__box {
    margin: 0 auto;
    height: max-content;
    width: 60vw;
    // padding-top: 4rem;
    position: relative;
    // padding: 4rem;

    @include respond(phone-big) {
      width: 80vw;
    }
    @include respond(phone-small) {
      width: 80vw;
    }

    &__item {
      height: 100%;
      padding: 4rem 6rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      border-radius: $default-border-radius;
      background-color: $color-grey-light-1;
      overflow: hidden;
    }
    &__img {
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      @include respond(phone-small) {
        width: 6rem;
        height: 6rem;
      }
    }
    &__text {
      font-size: 2rem;
      line-height: 1.6;
      @include respond(phone-small) {
        font-size: 1.6rem;
      }
    }
    &__name {
      font-size: 2.4rem;
      color: $color-primary-light;
      margin-top: auto;
      @include respond(phone-small) {
        font-size: 2rem;
      }
    }
  }
  //FOR A EMPTY BOX
  &__message {
    @include absCenter;
    background-color: rgba($color-primary-text-light, 0.4);
    backdrop-filter: blur(4px);
    height: 100%;
    width: 100%;
    border-radius: $default-border-radius;
    z-index: 1000;

    &__text {
      @include absCenter;
      font-size: 2.4rem;
      font-weight: 600;
      text-align: center;
      width: 80%;
      color: $color-primary-text-dark;
      z-index: 1001;
    }
    &__overlay {
      width: 100%;
      height: 100%;
      // background-color: rgba($color-primary-text-light, 0.5);
      backdrop-filter: blur(4px);
      transition: all 0.5s;
      border-radius: $default-border-radius;
    }
  }
}
