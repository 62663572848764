body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 1.7;
}

.heading-1 {
  font-size: 4rem;
  line-height: 1.05;

  @include respond(phone-big) {
    font-size: 3rem;
  }
}
.heading-2 {
  font-size: 3.2rem;
  line-height: 1.1;
  color: $color-primary;
  font-weight: 400;

  @include respond(phone-big) {
    font-size: 2.4rem;
  }
}
.heading-3 {
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 600;
}
.heading-4 {
  font-size: 2.4rem;
  line-height: 1.1;
  font-weight: 500;
}
.paragraph {
  font-size: $default-font-size;
  line-height: 1.6;

  // @include respond(phone-big) {
  //   font-size: 1.4rem;
  // }
}

.subheading {
  text-transform: uppercase;
  font-size: 1.4rem;
  margin-bottom: 1.2rem;
  color: $color-primary;
}
