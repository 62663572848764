.cta {
  box-shadow: 0 2rem 4rem rgba($color-primary-text-dark, 0.15);
  border-radius: $default-border-radius;
  background-image: linear-gradient(
    to left,
    $color-primary-secondary,
    $color-grey-light-3,
    $color-grey-light-5
  );
  overflow: hidden;
  width: max-content;
  padding: 12rem;
  display: flex;

  @include respond(tab-land) {
    padding: 8rem;
  }
  @include respond(phone-big) {
    padding: 4rem 10rem;
    flex-direction: column;
    gap: 4rem;
    background-image: linear-gradient(
      to left top,
      $color-primary-secondary,
      $color-grey-light-3,
      $color-grey-light-5
    );
  }

  @include respond(phone) {
    padding: 4rem 8rem;
    width: 100%;
    gap: 2rem;
  }
  @include respond(phone-small) {
    padding: 4rem;
  }

  &__text-box {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 70%;
    padding-right: 2rem;

    @include respond(phone-big) {
      padding-right: 0;
      width: 100%;
      padding-bottom: 4rem;
    }

    h2 {
      color: $color-primary-dark;
      font-weight: 600;
      line-height: 1.4;

      @include respond(tab-land) {
        font-size: 3rem;
      }
      @include respond(phone) {
        // text-align: center;
      }
    }
    p {
      font-size: 2rem;
      color: $color-primary;

      @include respond(tab-land) {
        color: $color-primary-text-medium;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &__btn {
      display: flex;
      gap: 2rem;
      align-items: center;

      text-align: center;

      @include respond(phone-big) {
        justify-content: flex-start;
      }

      &__icon {
        display: flex;
        // font-size: 4rem;
        height: 5rem;
        width: 5rem;
        fill: $color-primary-dark;
        // color: $color-primary-dark;

        @include respond(phone-small) {
          display: none;
        }
      }
    }
  }
}
