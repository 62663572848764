/* Popup WINDOW */
.review {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50rem;
  z-index: 1001;
  transition: all 0.5s;

  @include respond(phone) {
    width: 75%;
  }
  @include respond(phone-small) {
    width: 8 5%;
  }

  &__heading {
    font-size: 3rem;
    text-align: center;
    color: $color-primary-text-light;
  }
}

.form {
  padding: 4rem;
  border: 3px solid $color-primary;
  background-color: $color-primary-text-medium;
  border-radius: $default-border-radius;
  box-shadow: 0 0 1rem 2rem rgba($color-primary-text-dark, 0.4);

  &__group:not(:last-child) {
    margin-bottom: 1rem;
  }
  &__group:last-child {
    text-align: center;
  }

  &__input {
    font-size: 1.5rem;
    font-family: inherit;
    color: $color-primary-text-medium;
    padding: 1.5rem 2rem;
    border-radius: $default-border-radius;
    background-color: rgba($color-grey-light-1, 0.7);
    border: none;
    border-bottom: 3px solid transparent;
    width: 100%;
    display: block;
    transition: all 0.3s;

    &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-primary-text-dark, 0.1);
      border-bottom: 3px solid $color-primary;
    }

    &:focus:invalid {
      border-bottom: 3px solid orangered;
    }

    &::-webkit-input-placeholder {
      color: $color-grey-light-4;
    }
  }

  &__label {
    font-size: 1.2rem;
    font-weight: 500;
    margin-left: 2rem;
    margin-top: 0.7rem;
    display: block;
    transition: all 0.3s;
    color: $color-primary-text-light;
    font-family: inherit;
  }

  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem);
  }
}

.btn--close-popup {
  font-family: inherit;
  color: $color-primary-text-medium;
  position: absolute;
  top: 0.5rem;
  right: 2rem;
  font-size: 4rem;
  cursor: pointer;
  border: none;
  background: none;
}
