.about {
  &__text-box {
    display: grid;
    grid-gap: 3rem;
    margin: 2rem;

    h1 {
      animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation-delay: 0s;
    }
    p {
      font-size: 1.8rem;
      animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation-delay: 0.1s;
    }

    @include respond(phone) {
      grid-row: 1/2;
      padding: 2rem;
    }
  }
  &__btns {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    a {
      &:nth-child(1) {
        animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.61, 0.355, 1)
          both;

        animation-delay: 0.5s;
      }
      &:nth-child(2) {
        animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.61, 0.355, 1)
          both;

        animation-delay: 1s;
      }
    }

    @include respond(phone-big) {
      flex-direction: column;
    }
    @include respond(phone) {
      flex-direction: row;
    }
    @include respond(phone-small) {
      flex-direction: column;
    }
  }
}
