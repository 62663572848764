.social {
  justify-self: center;
  display: flex;
  flex-direction: column;
  @include respond(phone-small) {
    justify-content: center;
    align-items: center;
  }
  &__logo:link {
    margin: 0 auto;
  }
  &__logo {
    &__img {
      width: 50%;
      // width: 20%;
      margin-bottom: 3rem;
      @include respond(phone) {
        width: 20%;
        // width: 8%;
        // margin-bottom: 0;
        margin-bottom: 1rem;
      }
      @include respond(phone-small) {
        display: block;
        margin: 0 auto;
        width: 50%;
      }
    }
  }
  @include respond(phone) {
    margin-top: 4rem;
    padding-top: 2rem;
    grid-column: 1/-1;
    grid-row: 2/3;
    border-top: 2px solid $color-grey-light-1;
  }
  @include respond(phone-small) {
    margin-top: 4rem;
    grid-row: 3/4;
    border-top: 2px solid $color-grey-light-1;
  }

  &__links {
    list-style: none;
    display: flex;
    gap: 2rem;
    align-items: center;
    margin-bottom: 2rem;

    &__link:link,
    &__link:visited {
      display: inline-block;
      text-decoration: none;
      transition: all 0.3s;
    }
    &__link:hover {
      transform: scale(1.1);
    }
    &__link:active {
      transform: scale(1.05);
    }

    &__icon {
      height: 4rem;
      width: 4rem;
      fill: $color-grey-light-3;
      transition: all 0.3s;

      // font-size: 3rem;
      // color: $color-grey-light-3;
      &:hover {
        fill: $color-primary-medium;
      }
    }
  }

  &__copyright {
    font-size: 1.4rem;
    line-height: 1.6;
    color: $color-grey-light-3;
    margin-top: auto;
  }
}

.contacts {
  justify-self: center;
  color: $color-primary;
  align-self: center;

  &__heading {
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    font-family: inherit;
    margin-bottom: 3rem;
  }
  &__contact {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;

    font-style: normal;
    font-size: 1.6rem;
    line-height: 1.6;

    &__link:link,
    &__link:visited {
      text-decoration: none;
      color: inherit;
      transition: all 0.3s;
    }

    &__link:hover {
      transform: translateY(-2px) scale(1.03);
      color: $color-primary-dark;
    }
    &__link:active {
      transform: translateY(-1px) scale(1.03);
    }
  }
}

.nav {
  justify-self: center;

  color: $color-primary;

  @include respond(phone-small) {
    margin-top: 4rem;
    padding-top: 4rem;
    border-top: 2px solid $color-grey-light-1;
  }
  &__nav {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;

    &__link:link,
    &__link:visited {
      padding: 1rem;
      border: 2px solid transparent;
      border-radius: $default-border-radius;
      text-decoration: none;
      font-size: $default-font-size;
      color: $color-primary-light;
      transition: all 0.3s;
    }

    &__link:hover {
      color: $color-primary-dark;
      border: 2px solid $color-primary;
    }
    &__link:active {
      background-color: $color-primary;
      color: $color-grey-light-2;
    }
  }
}
