.statistics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  align-items: center;
  justify-content: center;

  @include respond(phone-big) {
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    justify-self: center;
  }

  @include respond(phone) {
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
    align-items: center;
  }
  @include respond(phone-small) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
    justify-self: center;
  }

  &__box {
    justify-self: center;
    height: 100%;
    width: 78%;
    display: flex;
    flex-direction: column;
    box-shadow: 0rem 0.5rem 2rem 0.6rem rgba($color-primary-text-dark, 0.09);
    border-radius: $default-border-radius;
    padding: 2rem;
    // max-width: 85%;
    transition: all 0.3s;

    @include respond(phone) {
      max-width: 100%;
    }
    @include respond(phone-small) {
      width: 90%;
    }

    &__list-icon {
      height: 4rem;
      width: 4rem;
      fill: $color-primary-dark;
      // font-size: 3rem;
      // color: $color-primary-dark;

      @include respond(phone-small) {
        height: 3rem;
        width: 3rem;
        // font-size: 2.4rem;
      }
    }
    &__number {
      display: block;
      font-size: 3rem;
      line-height: 1.6;
      color: $color-grey-light-3;
      transition: all 0.2s;

      @include respond(phone-small) {
        font-size: 2.4rem;
      }
    }
    &__text {
      font-weight: 500;
      line-height: 1.2;
      font-size: 1.4rem;
      color: $color-primary-light;

      @include respond(phone-big) {
        font-size: 1.2rem;
      }
    }

    &:hover {
      transform: skewY(3deg) scale(1.08);
    }
  }
}
