.slide-in-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-top;
}

@keyframes slide-in-top {
  0% {
    opacity: 0;
    transform: translateY(-1000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-in-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-left;
}

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-1000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-in-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-right;
}

@keyframes slide-in-right {
  0% {
    opacity: 0;
    transform: translateX(1000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-in-bottom {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-bottom;
}

.tracking-in-contract {
  animation: .8s cubic-bezier(.215, .61, .355, 1) both tracking-in-contract;
}

@keyframes tracking-in-contract {
  0% {
    opacity: 0;
  }

  40% {
    opacity: .6;
  }

  100% {
    opacity: 1;
  }
}

.slide-in-blurred-bottom {
  animation: .6s cubic-bezier(.23, 1, .32, 1) both slide-in-blurred-bottom;
}

@keyframes slide-in-blurred-bottom {
  0% {
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
    transform: translateY(1000px)scaleY(2.5)scaleX(.2);
  }

  100% {
    transform-origin: 50%;
    -webkit-filter: blur();
    filter: blur();
    opacity: 1;
    transform: translateY(0)scaleY(1)scaleX(1);
  }
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

@media only screen and (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}

@media only screen and (max-width: 81.25em) {
  html {
    font-size: 56.25%;
  }
}

@media only screen and (max-width: 62.5em) {
  html {
    font-size: 50%;
  }
}

body {
  box-sizing: border-box;
  color: #050f18;
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 1;
}

::selection {
  color: #d6ebfc;
  background-color: #339af0;
}

img {
  transition: filter .5s;
}

.lazy-img {
  filter: blur(5px);
}

body {
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 1.7;
}

.heading-1 {
  font-size: 4rem;
  line-height: 1.05;
}

@media only screen and (max-width: 56.25em) {
  .heading-1 {
    font-size: 3rem;
  }
}

.heading-2 {
  color: #339af0;
  font-size: 3.2rem;
  font-weight: 400;
  line-height: 1.1;
}

@media only screen and (max-width: 56.25em) {
  .heading-2 {
    font-size: 2.4rem;
  }
}

.heading-3 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.2;
}

.heading-4 {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.1;
}

.paragraph {
  font-size: 1.6rem;
  line-height: 1.6;
}

.subheading {
  text-transform: uppercase;
  color: #339af0;
  margin-bottom: 1.2rem;
  font-size: 1.4rem;
}

.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}

.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
}

@media only screen and (max-width: 62.5em) {
  .u-margin-bottom-medium {
    margin-bottom: 3rem !important;
  }
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}

@media only screen and (max-width: 62.5em) {
  .u-margin-bottom-big {
    margin-bottom: 5rem !important;
  }
}

.u-margin-top-big {
  margin-top: 8rem !important;
}

.u-margin-top-huge {
  margin-top: 10rem !important;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.btn, .btn:link, .btn:visited {
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  border-radius: 10rem;
  padding: .8em 2rem;
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem #050f1833;
}

.btn:active {
  outline: none;
  transform: translateY(-1px);
  box-shadow: 0 .5rem 1rem #050f1833;
}

.btn:focus {
  outline: none;
}

.btn--full {
  color: #ebf5fe;
  background-image: linear-gradient(to right, #5caef3, #339af0);
}

.btn--outline {
  width: max-content;
  color: #297bc0;
  background-image: linear-gradient(to right, #ebf5fe, #add7f9);
}

.btn__gallery__box {
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  display: flex;
}

.btn__gallery__box p {
  display: inline-block;
}

.btn__form {
  color: #e4e9ff;
  width: 100%;
  background-color: #5366b3;
  padding: 1.4rem;
}

.btn__form:hover {
  color: #5366b3;
  background-color: #f1f4ff;
}

.btn--review {
  margin: 0 auto;
}

@media only screen and (max-width: 56.25em) {
  .btn--review {
    grid-column: auto;
  }
}

.btn--close-popup {
  color: #0f2e48;
  cursor: pointer;
  background: none;
  border: none;
  font-family: inherit;
  font-size: 4rem;
  position: absolute;
  top: .5rem;
  right: 2rem;
}

.btn--close-review {
  color: #ebf5fe;
  cursor: pointer;
  background: none;
  border: none;
  font-family: inherit;
  font-size: 4rem;
  position: absolute;
  top: .5rem;
  right: 2rem;
}

.about__images {
  grid-gap: 1rem;
  border-radius: 2rem;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  margin: 2rem;
  display: grid;
}

@media only screen and (max-width: 37.5em) {
  .about__images {
    width: 90%;
    justify-self: center;
    margin: 0;
  }
}

.about__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 10;
  border-radius: 2rem;
  transition: all .2s;
  display: block;
  box-shadow: 0 1.5rem 4rem #050f1866;
}

.about__img--1, .about__img--2 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) .2s both slide-in-left;
}

.about__img--3 {
  grid-area: 1 / 2 / 3 / 3;
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-left;
}

.about__img:hover {
  z-index: 20;
  transform: scale(1.05)translateY(-.5rem);
  box-shadow: 0 2.5rem 4rem #050f1880;
}

.about:hover .about__img:not(:hover) {
  transform: scale(.95);
}

.card {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 1rem;
  transition: all .5s;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1rem 2rem #050f1833;
}

.card__link:link, .card__link:active {
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.card:hover {
  transform: scale(1.05);
}

.card:hover .card__img {
  opacity: .8;
}

.card__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all .3s;
  display: block;
}

.sticky .header {
  border-radius: none;
  width: 100vw;
  height: 8rem;
  z-index: 999;
  background-color: #ebf5fef7;
  border-radius: 2rem;
  justify-self: center;
  padding: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  box-shadow: 0 1.2rem 3.2rem #050f1808;
}

.sticky .section-about {
  margin-top: 8rem;
}

.popup {
  max-width: 80vw;
  z-index: 1002;
  transition: all .5s;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 56.25em) {
  .popup {
    max-width: 90vw;
  }
}

.popup__cta {
  width: 70vw;
  background-image: linear-gradient(to left, #5366b3, #add7f9, #f1f4ff);
  border-radius: 2rem;
  gap: 2rem;
  padding: 10rem;
  display: flex;
  overflow: hidden;
  box-shadow: 0 2rem 4rem #050f1826;
}

@media only screen and (max-width: 81.25em) {
  .popup__cta {
    padding: 6rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .popup__cta {
    width: 90vw;
  }
}

@media only screen and (max-width: 37.5em) {
  .popup__cta {
    width: 90vw;
    background-image: linear-gradient(to left, #70b8f5, #339af0, #297bc0);
    flex-direction: column;
    padding: 4rem;
  }
}

.popup__cta__text-box {
  width: 70%;
  flex-direction: column;
  gap: 2rem;
  display: flex;
}

@media only screen and (max-width: 37.5em) {
  .popup__cta__text-box {
    width: 100%;
  }
}

.popup__cta__text-box h2 {
  color: #297bc0;
  font-weight: 600;
}

@media only screen and (max-width: 37.5em) {
  .popup__cta__text-box h2 {
    color: #add7f9;
  }
}

.popup__cta__text-box p {
  color: #339af0;
  font-size: 2rem;
}

@media only screen and (max-width: 37.5em) {
  .popup__cta__text-box p {
    color: #add7f9;
  }
}

.popup__cta__form {
  flex-direction: column;
  gap: 2rem;
  display: flex;
}

.popup__cta__form__btn {
  text-align: center;
  align-items: center;
  gap: 2rem;
  display: flex;
}

.popup__cta__form__btn__icon {
  color: #297bc0;
  font-size: 4rem;
  display: flex;
}

@media only screen and (max-width: 37.5em) {
  .popup__cta__form__btn__icon {
    color: #add7f9;
  }
}

.overlay {
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  z-index: 1000;
  background-color: #050f18e6;
  transition: all .5s;
  position: fixed;
  top: 0;
  left: 0;
}

.mobile__nav {
  display: none;
}

.btn-mobile-nav {
  cursor: pointer;
  background: none;
  border: none;
  display: none;
}

.icon-mobile-nav {
  height: 4rem;
  width: 4rem;
  fill: #339af0;
  border-radius: 50%;
  margin: 1rem;
  transition: all .3s;
}

#close-outline {
  display: none;
}

@media only screen and (max-width: 62.5em) {
  .header__nav {
    display: none;
  }

  .btn-mobile-nav {
    z-index: 9999;
    display: block;
  }

  .mobile__nav {
    -webkit-backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    width: 100vw;
    height: 100vh;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    background-color: #ffffffb3;
    justify-content: center;
    align-items: center;
    transition: all .5s ease-in;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
  }

  .nav-open {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    z-index: 1000;
    transform: translateX(0);
  }

  .nav-open #close-outline {
    display: block;
  }

  .nav-open #menu-outline {
    display: none;
  }

  .mobile__nav--list {
    color: #050f18;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    list-style: none;
    display: flex;
  }

  .mobile__nav--link:link, .mobile__nav--link:visited {
    color: #050f18;
    text-transform: uppercase;
    border: 2px solid #0000;
    border-radius: 2rem;
    padding: 1rem;
    font-size: 2.4rem;
    font-weight: 700;
    text-decoration: none;
  }

  .mobile__nav--link:active, .mobile__nav--link:hover {
    border: 2px solid #339af0;
    box-shadow: 0 1rem 2rem #050f184d;
  }
}

.review {
  width: 50rem;
  z-index: 1001;
  transition: all .5s;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 37.5em) {
  .review {
    width: 75%;
  }
}

@media only screen and (max-width: 28.125em) {
  .review {
    width: 8 5%;
  }
}

.review__heading {
  text-align: center;
  color: #d6ebfc;
  font-size: 3rem;
}

.form {
  background-color: #0f2e48;
  border: 3px solid #339af0;
  border-radius: 2rem;
  padding: 4rem;
  box-shadow: 0 0 1rem 2rem #050f1866;
}

.form__group:not(:last-child) {
  margin-bottom: 1rem;
}

.form__group:last-child {
  text-align: center;
}

.form__input {
  color: #0f2e48;
  width: 100%;
  background-color: #ebf5feb3;
  border: none;
  border-bottom: 3px solid #0000;
  border-radius: 2rem;
  padding: 1.5rem 2rem;
  font-family: inherit;
  font-size: 1.5rem;
  transition: all .3s;
  display: block;
}

.form__input:focus {
  border-bottom: 3px solid #339af0;
  outline: none;
  box-shadow: 0 1rem 2rem #050f181a;
}

.form__input:focus:invalid {
  border-bottom: 3px solid #ff4500;
}

.form__input::-webkit-input-placeholder {
  color: #e4e9ff;
}

.form__label {
  color: #d6ebfc;
  margin-top: .7rem;
  margin-left: 2rem;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 500;
  transition: all .3s;
  display: block;
}

.form__input:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4rem);
}

.btn--close-popup {
  color: #0f2e48;
  cursor: pointer;
  background: none;
  border: none;
  font-family: inherit;
  font-size: 4rem;
  position: absolute;
  top: .5rem;
  right: 2rem;
}

.social {
  flex-direction: column;
  justify-self: center;
  display: flex;
}

@media only screen and (max-width: 28.125em) {
  .social {
    justify-content: center;
    align-items: center;
  }
}

.social__logo:link {
  margin: 0 auto;
}

.social__logo__img {
  width: 50%;
  margin-bottom: 3rem;
}

@media only screen and (max-width: 37.5em) {
  .social__logo__img {
    width: 20%;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 28.125em) {
  .social__logo__img {
    width: 50%;
    margin: 0 auto;
    display: block;
  }
}

@media only screen and (max-width: 37.5em) {
  .social {
    border-top: 2px solid #ebf5fe;
    grid-area: 2 / 1 / 3 / -1;
    margin-top: 4rem;
    padding-top: 2rem;
  }
}

@media only screen and (max-width: 28.125em) {
  .social {
    border-top: 2px solid #ebf5fe;
    grid-row: 3 / 4;
    margin-top: 4rem;
  }
}

.social__links {
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
  list-style: none;
  display: flex;
}

.social__links__link:link, .social__links__link:visited {
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.social__links__link:hover {
  transform: scale(1.1);
}

.social__links__link:active {
  transform: scale(1.05);
}

.social__links__icon {
  height: 4rem;
  width: 4rem;
  fill: #add7f9;
  transition: all .3s;
}

.social__links__icon:hover {
  fill: #5caef3;
}

.social__copyright {
  color: #add7f9;
  margin-top: auto;
  font-size: 1.4rem;
  line-height: 1.6;
}

.contacts {
  color: #339af0;
  place-self: center;
}

.contacts__heading {
  text-align: center;
  margin-bottom: 3rem;
  font-family: inherit;
  font-size: 2rem;
  font-weight: 600;
}

.contacts__contact {
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  font-size: 1.6rem;
  font-style: normal;
  line-height: 1.6;
  display: flex;
}

.contacts__contact__link:link, .contacts__contact__link:visited {
  color: inherit;
  text-decoration: none;
  transition: all .3s;
}

.contacts__contact__link:hover {
  color: #297bc0;
  transform: translateY(-2px)scale(1.03);
}

.contacts__contact__link:active {
  transform: translateY(-1px)scale(1.03);
}

.nav {
  color: #339af0;
  justify-self: center;
}

@media only screen and (max-width: 28.125em) {
  .nav {
    border-top: 2px solid #ebf5fe;
    margin-top: 4rem;
    padding-top: 4rem;
  }
}

.nav__nav {
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  list-style: none;
  display: flex;
}

.nav__nav__link:link, .nav__nav__link:visited {
  color: #70b8f5;
  border: 2px solid #0000;
  border-radius: 2rem;
  padding: 1rem;
  font-size: 1.6rem;
  text-decoration: none;
  transition: all .3s;
}

.nav__nav__link:hover {
  color: #297bc0;
  border: 2px solid #339af0;
}

.nav__nav__link:active {
  color: #d6ebfc;
  background-color: #339af0;
}

.container {
  grid-template-rows: minmax(min-content, calc(100vh - 8rem)) repeat(3, max-content);
  display: grid;
}

@media only screen and (max-width: 62.5em) {
  .container {
    grid-template-rows: minmax(min-content, calc(100vh - 6rem)) repeat(3, max-content);
  }
}

.header {
  height: 8rem;
  max-width: 100vw;
  background-color: #ebf5fe;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header__logo:link, .header__logo:visited {
  color: #5caef3;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.header__logo:hover {
  color: #339af0;
  transform: scale(1.05);
}

.header__logo:active {
  color: #297bc0;
  transform: scale(1.02);
}

.header__logo--icon {
  height: 5rem;
  width: 5rem;
  fill: #339af0;
  margin: 2rem;
  animation: .75s cubic-bezier(.25, .46, .45, .94) both slide-in-top;
}

.header__nav {
  font-size: 1.8rem;
  font-weight: 500;
}

.header__nav--list {
  align-items: center;
  gap: 3rem;
  padding: 2rem;
  display: flex;
}

.header__nav--list li {
  list-style: none;
}

.header__nav--list li a, .header__nav--list li a:link, .header__nav--list li a:visited {
  cursor: pointer;
  color: #0f2e48;
  background-color: #d6ebfc;
  border-radius: 33rem;
  padding: 1rem 2rem;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.header__nav--list li a:hover {
  color: #050f18;
  background-color: #70b8f5;
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem #050f1833;
}

.header__nav--list li a:active {
  transform: translateY(-1px);
  box-shadow: 0 2rem 4rem #050f1833;
}

.header__nav--list li:nth-child(1) {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-top;
}

.header__nav--list li:nth-child(2) {
  animation: .5s cubic-bezier(.25, .46, .45, .94) .1s both slide-in-top;
}

.header__nav--list li:nth-child(3) {
  animation: .5s cubic-bezier(.25, .46, .45, .94) .2s both slide-in-top;
}

.header__nav--list li:nth-child(4) {
  animation: .5s cubic-bezier(.25, .46, .45, .94) .3s both slide-in-top;
}

#header__nav--link--blue:link, #header__nav--link--blue:visited {
  color: #ebf5fe;
  background-image: linear-gradient(to right, #5caef3, #339af0);
}

#header__nav--link--blue:hover, #header__nav--link--blue:active {
  color: #050f18;
  background-color: #70b8f5;
}

.about__text-box {
  grid-gap: 3rem;
  margin: 2rem;
  display: grid;
}

.about__text-box h1 {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-right;
}

.about__text-box p {
  font-size: 1.8rem;
  animation: .5s cubic-bezier(.25, .46, .45, .94) .1s both slide-in-right;
}

@media only screen and (max-width: 37.5em) {
  .about__text-box {
    grid-row: 1 / 2;
    padding: 2rem;
  }
}

.about__btns {
  justify-content: center;
  align-items: center;
  gap: 2rem;
  display: flex;
}

.about__btns a:nth-child(1) {
  animation: .8s cubic-bezier(.215, .61, .355, 1) .5s both tracking-in-contract;
}

.about__btns a:nth-child(2) {
  animation: .8s cubic-bezier(.215, .61, .355, 1) 1s both tracking-in-contract;
}

@media only screen and (max-width: 56.25em) {
  .about__btns {
    flex-direction: column;
  }
}

@media only screen and (max-width: 37.5em) {
  .about__btns {
    flex-direction: row;
  }
}

@media only screen and (max-width: 28.125em) {
  .about__btns {
    flex-direction: column;
  }
}

.statistics {
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  align-items: center;
  gap: 2rem;
  display: grid;
}

@media only screen and (max-width: 56.25em) {
  .statistics {
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    justify-self: center;
  }
}

@media only screen and (max-width: 37.5em) {
  .statistics {
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
    align-items: center;
  }
}

@media only screen and (max-width: 28.125em) {
  .statistics {
    width: 100%;
    grid-template-columns: 1fr 1fr;
    justify-self: center;
  }
}

.statistics__box {
  height: 100%;
  width: 78%;
  border-radius: 2rem;
  flex-direction: column;
  justify-self: center;
  padding: 2rem;
  transition: all .3s;
  display: flex;
  box-shadow: 0 .5rem 2rem .6rem #050f1817;
}

@media only screen and (max-width: 37.5em) {
  .statistics__box {
    max-width: 100%;
  }
}

@media only screen and (max-width: 28.125em) {
  .statistics__box {
    width: 90%;
  }
}

.statistics__box__list-icon {
  height: 4rem;
  width: 4rem;
  fill: #297bc0;
}

@media only screen and (max-width: 28.125em) {
  .statistics__box__list-icon {
    height: 3rem;
    width: 3rem;
  }
}

.statistics__box__number {
  color: #add7f9;
  font-size: 3rem;
  line-height: 1.6;
  transition: all .2s;
  display: block;
}

@media only screen and (max-width: 28.125em) {
  .statistics__box__number {
    font-size: 2.4rem;
  }
}

.statistics__box__text {
  color: #70b8f5;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.2;
}

@media only screen and (max-width: 56.25em) {
  .statistics__box__text {
    font-size: 1.2rem;
  }
}

.statistics__box:hover {
  transform: skewY(3deg)scale(1.08);
}

.swiper {
  width: 100%;
  height: 100%;
  background-color: #ebf5fe;
  border: 2px solid #339af0;
  border-radius: 2rem;
  overflow: hidden;
  box-shadow: 0 2rem 4rem #0f2e484d;
}

.testimonials__h3 {
  margin-bottom: 4rem;
}

.testimonials__box {
  height: max-content;
  width: 60vw;
  margin: 0 auto;
  position: relative;
}

@media only screen and (max-width: 56.25em) {
  .testimonials__box {
    width: 80vw;
  }
}

@media only screen and (max-width: 28.125em) {
  .testimonials__box {
    width: 80vw;
  }
}

.testimonials__box__item {
  height: 100%;
  background-color: #ebf5fe;
  border-radius: 2rem;
  flex-direction: column;
  gap: 2rem;
  padding: 4rem 6rem;
  display: flex;
  overflow: hidden;
}

.testimonials__box__img {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
}

@media only screen and (max-width: 28.125em) {
  .testimonials__box__img {
    width: 6rem;
    height: 6rem;
  }
}

.testimonials__box__text {
  font-size: 2rem;
  line-height: 1.6;
}

@media only screen and (max-width: 28.125em) {
  .testimonials__box__text {
    font-size: 1.6rem;
  }
}

.testimonials__box__name {
  color: #70b8f5;
  margin-top: auto;
  font-size: 2.4rem;
}

@media only screen and (max-width: 28.125em) {
  .testimonials__box__name {
    font-size: 2rem;
  }
}

.testimonials__message {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  height: 100%;
  width: 100%;
  z-index: 1000;
  background-color: #d6ebfc66;
  border-radius: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.testimonials__message__text {
  text-align: center;
  width: 80%;
  color: #050f18;
  z-index: 1001;
  font-size: 2.4rem;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.testimonials__message__overlay {
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-radius: 2rem;
  transition: all .5s;
}

.how__heading {
  margin-bottom: 4rem;
  margin-right: auto;
}

.how__container {
  grid-gap: 8rem;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  display: grid;
}

@media only screen and (max-width: 62.5em) {
  .how__container {
    grid-gap: 4rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .how__container {
    grid-template-columns: 1fr;
  }
}

.how__container__text-box {
  height: 100%;
  border-radius: 2rem;
  padding: 3.5rem;
  transition: all .3s;
  box-shadow: 0 1rem 4rem 1rem #050f1826;
}

@media only screen and (max-width: 56.25em) {
  .how__container__text-box {
    padding: 2rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .how__container__text-box {
    width: 85%;
  }
}

@media only screen and (max-width: 28.125em) {
  .how__container__text-box {
    width: 95%;
  }
}

.how__container__text-box__heading {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.how__container__text-box h3 {
  color: #297bc0;
  font-size: 3rem;
  line-height: 1;
  display: inline-block;
}

@media only screen and (max-width: 37.5em) {
  .how__container__text-box h3 {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 28.125em) {
  .how__container__text-box h3 {
    font-size: 2.4rem;
  }
}

.how__container__text-box span {
  color: #add7f9;
  padding-right: 2rem;
  font-size: 8rem;
  font-weight: 700;
  display: block;
}

@media only screen and (max-width: 28.125em) {
  .how__container__text-box span {
    font-size: 6rem;
  }
}

.how__container__img-box {
  border-radius: 2rem;
  transition: all .5s;
  overflow: hidden;
  box-shadow: 0 1rem 2rem #050f1826;
}

.how__container__img-box__img {
  object-fit: fill;
  width: 100%;
  z-index: 100;
  display: block;
  overflow: hidden;
}

.how__container__img-box-2 {
  grid-area: 2 / 1 / 3 / 2;
}

@media only screen and (max-width: 37.5em) {
  .how__container__img-box-2 {
    grid-row: 4 / 5;
  }
}

.how__container__img-box-4 {
  grid-area: 4 / 1 / 5 / 2;
}

@media only screen and (max-width: 37.5em) {
  .how__container__img-box-4 {
    grid-row: 8 / 9;
  }

  .how__container__img-box {
    width: 85%;
  }
}

.gallery__container {
  grid-gap: 6rem;
  width: 90%;
  grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
  margin: 0 auto;
  display: grid;
}

@media only screen and (max-width: 93.75em) {
  .gallery__container {
    grid-gap: 4rem;
    width: 80%;
  }
}

@media only screen and (max-width: 56.25em) {
  .gallery__container {
    width: 95%;
  }
}

.gallery__heading {
  justify-items: center;
  display: grid;
}

.gallery__card {
  border-radius: 2rem;
  flex-direction: column;
  padding: 2rem;
  display: flex;
  position: relative;
  box-shadow: 0 4rem 5rem #339af01a;
}

.gallery__card__heading {
  text-align: center;
  width: 80%;
  background-color: #339af0;
  border-radius: 2rem;
  align-self: center;
  margin: 0 auto;
  padding: 1rem 2rem;
  font-size: 2rem;
  display: block;
  transform: translateY(-50%);
}

.gallery__card__text {
  text-indent: 2rem;
  font-size: 1.6rem;
}

@media only screen and (max-width: 37.5em) {
  .gallery__card__text {
    font-size: 1.5rem;
  }
}

.gallery__card__logo:link, .gallery__card__logo:active {
  color: #5caef3;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
  position: absolute;
  top: 3rem;
  left: 3rem;
}

@media only screen and (max-width: 37.5em) {
  .gallery__card__logo:link, .gallery__card__logo:active {
    top: 4rem;
    left: 4rem;
  }
}

.gallery__card__logo:hover {
  color: #339af0;
  transform: scale(1.05);
}

.gallery__card__logo:active {
  color: #297bc0;
  transform: scale(1.02);
}

.gallery__card__logo--icon {
  height: 5rem;
  width: 5rem;
  fill: #297bc0;
}

.mySwiper2 {
  width: 100%;
}

.mySwiper2 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 2rem;
}

.swiper-slide {
  height: 45rem;
}

.featured-in__box {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.featured-in__box h4 {
  color: #70b8f5;
  text-align: center;
  align-self: center;
  margin-bottom: 4rem;
  font-size: 3rem;
}

@media only screen and (max-width: 37.5em) {
  .featured-in__box h4 {
    margin-bottom: 2rem;
    font-size: 2rem;
  }
}

.featured-in__logos {
  justify-content: space-around;
  display: flex;
}

@media only screen and (max-width: 28.125em) {
  .featured-in__logos {
    grid-gap: 2rem;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(6, 1fr);
    justify-items: center;
    display: grid;
  }
}

.featured-in__logos img {
  height: 4rem;
  filter: brightness(0);
  opacity: .5;
  transition: all .3s;
}

@media only screen and (max-width: 56.25em) {
  .featured-in__logos img {
    height: 3rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .featured-in__logos img {
    height: 2.8rem;
  }
}

.featured-in__logos img:hover {
  filter: brightness();
  opacity: 1;
  transform: scale(1.1);
}

@media only screen and (max-width: 28.125em) {
  .featured-in__logo-1 {
    grid-column: 1 / 3;
  }

  .featured-in__logo-2 {
    grid-column: 3 / 5;
  }

  .featured-in__logo-3 {
    grid-column: 5 / 7;
  }

  .featured-in__logo-4 {
    grid-column: 1 / 4;
  }

  .featured-in__logo-5 {
    grid-column: 4 / 7;
  }
}

.cta {
  width: max-content;
  background-image: linear-gradient(to left, #5366b3, #add7f9, #f1f4ff);
  border-radius: 2rem;
  padding: 12rem;
  display: flex;
  overflow: hidden;
  box-shadow: 0 2rem 4rem #050f1826;
}

@media only screen and (max-width: 81.25em) {
  .cta {
    padding: 8rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .cta {
    background-image: linear-gradient(to top left, #5366b3, #add7f9, #f1f4ff);
    flex-direction: column;
    gap: 4rem;
    padding: 4rem 10rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .cta {
    width: 100%;
    gap: 2rem;
    padding: 4rem 8rem;
  }
}

@media only screen and (max-width: 28.125em) {
  .cta {
    padding: 4rem;
  }
}

.cta__text-box {
  width: 70%;
  flex-direction: column;
  gap: 2rem;
  padding-right: 2rem;
  display: flex;
}

@media only screen and (max-width: 56.25em) {
  .cta__text-box {
    width: 100%;
    padding-bottom: 4rem;
    padding-right: 0;
  }
}

.cta__text-box h2 {
  color: #297bc0;
  font-weight: 600;
  line-height: 1.4;
}

@media only screen and (max-width: 81.25em) {
  .cta__text-box h2 {
    font-size: 3rem;
  }
}

.cta__text-box p {
  color: #339af0;
  font-size: 2rem;
}

@media only screen and (max-width: 81.25em) {
  .cta__text-box p {
    color: #0f2e48;
  }
}

.cta__form {
  flex-direction: column;
  gap: 2rem;
  display: flex;
}

.cta__form__btn {
  text-align: center;
  align-items: center;
  gap: 2rem;
  display: flex;
}

@media only screen and (max-width: 56.25em) {
  .cta__form__btn {
    justify-content: flex-start;
  }
}

.cta__form__btn__icon {
  height: 5rem;
  width: 5rem;
  fill: #297bc0;
  display: flex;
}

@media only screen and (max-width: 28.125em) {
  .cta__form__btn__icon {
    display: none;
  }
}

.section {
  transition: transform 1s, opacity 1s;
}

.section--hidden {
  opacity: 0;
  transform: translateY(8rem);
}

.section-about {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  max-width: 100vw;
  background-color: #ebf5fe;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 0 4rem 10rem;
  display: grid;
}

@media only screen and (max-width: 62.5em) {
  .section-about {
    padding: 0 0 4rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .section-about {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-columns: 1.5fr 1fr;
  }
}

@media only screen and (max-width: 37.5em) {
  .section-about {
    grid-template-columns: 1fr;
  }
}

.section-description {
  grid-row-gap: 4rem;
  max-width: 80vw;
  grid-template-columns: 1fr;
  justify-self: center;
  padding: 10rem 2rem 4rem;
  display: grid;
}

@media only screen and (max-width: 62.5em) {
  .section-description {
    max-width: 90vw;
    padding: 6rem 2rem 4rem;
  }
}

.section-description__h2 {
  text-align: center;
  height: max-content;
  background-color: #ebf5fe;
  border-radius: 2rem;
  justify-self: center;
  padding: 1rem 2rem;
}

.section-how {
  max-width: 80vw;
  grid-row: 2 / 3;
  justify-self: center;
  padding: 4rem 2rem 10rem;
  display: grid;
}

@media only screen and (max-width: 62.5em) {
  .section-how {
    max-width: 90vw;
  }
}

@media only screen and (max-width: 56.25em) {
  .section-how {
    max-width: 100vw;
    padding: 4rem 2rem 6rem;
  }
}

.section-gallery {
  max-width: 98vw;
  background-color: #ebf5fe;
  flex-direction: column;
  padding: 10rem 4rem;
  display: flex;
  overflow: hidden;
}

@media only screen and (max-width: 56.25em) {
  .section-gallery {
    padding: 6rem 0;
  }
}

.section-featured-in {
  max-width: 98vw;
  padding: 4rem 0;
}

.section-cta {
  max-width: 98vw;
  justify-self: center;
  padding: 10rem;
}

@media only screen and (max-width: 56.25em) {
  .section-cta {
    padding: 4rem;
  }
}

.footer {
  max-width: 98vw;
  border-top: 1px solid #d6ebfc;
  grid-template-columns: repeat(3, 1fr);
  padding: 10rem 4rem 2rem;
  display: grid;
}

@media only screen and (max-width: 56.25em) {
  .footer {
    justify-content: center;
    align-items: center;
    padding: 6rem 4rem 2rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .footer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 28.125em) {
  .footer {
    grid-template-columns: 1fr;
  }
}

.success {
  height: 100vh;
  position: relative;
}

.success-box {
  z-index: 9999;
  width: 50rem;
  background-color: #5366b3;
  border-radius: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  padding: 4rem;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 28.125em) {
  .success-box {
    width: 80vw;
  }
}

.success-box--icon {
  height: 12rem;
  width: 12rem;
  fill: #d6ebfc;
}

.success-box__text {
  color: #d6ebfc;
  font-size: 1.8rem;
}

/*# sourceMappingURL=index.e5e81587.css.map */
