// COLORS
$color-primary-text-dark: #050f18;
$color-primary-text-medium: #0f2e48;
$color-primary-text-light: #d6ebfc;

$color-primary-light: #70b8f5;
$color-primary-medium: #5caef3;

$color-primary: #339af0;
$color-primary-secondary: #5366b3;
$color-primary-dark: #297bc0;

$color-grey-light-1: #ebf5fe;
$color-grey-light-2: #d6ebfc;
$color-grey-light-3: #add7f9;
$color-grey-light-4: #e4e9ff;
$color-grey-light-5: #f1f4ff;

// FONT
$default-font-size: 1.6rem;

//Border radius
$default-border-radius: 2rem;
