.how {
  &__heading {
    margin-bottom: 4rem;
    margin-right: auto;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8rem;
    justify-items: center;
    align-items: center;

    @include respond(tab-port) {
      grid-gap: 4rem;
    }
    @include respond(phone) {
      grid-template-columns: 1fr;
    }

    &__text-box {
      box-shadow: 0rem 1rem 4rem 1rem rgba($color-primary-text-dark, 0.15);
      border-radius: $default-border-radius;
      padding: 3.5rem;
      height: 100%;
      transition: all 0.3s;

      @include respond(phone-big) {
        padding: 2rem;
      }
      @include respond(phone) {
        width: 85%;
      }
      @include respond(phone-small) {
        width: 95%;
      }

      &__heading {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      & h3 {
        font-size: 3rem;
        display: inline-block;
        color: $color-primary-dark;
        line-height: 1;

        @include respond(phone) {
          font-size: 4rem;
        }
        @include respond(phone) {
          font-size: 3rem;
        }
        @include respond(phone-small) {
          font-size: 2.4rem;
        }
      }
      span {
        padding-right: 2rem;
        font-size: 8rem;
        font-weight: 700;
        color: $color-grey-light-3;
        display: block;

        @include respond(phone-small) {
          font-size: 6rem;
        }
      }
    }

    &__img-box {
      transition: all 0.5s;
      border-radius: $default-border-radius;
      overflow: hidden;
      box-shadow: 0rem 1rem 2rem rgba($color-primary-text-dark, 0.15);

      &__img {
        object-fit: fill;
        display: block;
        width: 100%;
        overflow: hidden;
        z-index: 100;
      }

      &-2 {
        grid-column: 1/2;
        grid-row: 2/3;
        @include respond(phone) {
          grid-row: 4/5;
        }
      }
      &-4 {
        grid-column: 1/2;
        grid-row: 4/5;
        @include respond(phone) {
          grid-row: 8/9;
        }
      }
      @include respond(phone) {
        width: 85%;
      }
    }
  }
}
