*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

  @include respond(big-desktop) {
    font-size: 75%; //1rem = 12, 12/16
  }
  @include respond(tab-land) {
    font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
  }

  @include respond(tab-port) {
    font-size: 50%; //1 rem = 8px, 8/16 = 50%
  }
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  line-height: 1;
  font-weight: 400;
  color: $color-primary-text-dark;
}

::selection {
  background-color: $color-primary;
  color: $color-primary-text-light;
}

img {
  transition: filter 0.5s;
}

.lazy-img {
  filter: blur(5px);
}
