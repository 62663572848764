/* Popup WINDOW */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80vw;
  z-index: 1002;
  transition: all 0.5s;
  @include respond(phone-big) {
    max-width: 90vw;
  }

  &__cta {
    box-shadow: 0 2rem 4rem rgba($color-primary-text-dark, 0.15);
    border-radius: $default-border-radius;
    background-image: linear-gradient(
      to left,
      $color-primary-secondary,
      $color-grey-light-3,
      $color-grey-light-5
    );
    overflow: hidden;
    width: 70vw;
    padding: 10rem;
    display: flex;
    gap: 2rem;

    @include respond(tab-land) {
      padding: 6rem;
    }

    @include respond(phone-big) {
      width: 90vw;
    }
    @include respond(phone) {
      flex-direction: column;
      width: 90vw;
      padding: 4rem;
      background-image: linear-gradient(
        to left,
        $color-primary-light,
        $color-primary,
        $color-primary-dark
      );
    }

    &__text-box {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: 70%;

      @include respond(phone) {
        width: 100%;
      }

      h2 {
        color: $color-primary-dark;
        font-weight: 600;
        @include respond(phone) {
          color: $color-grey-light-3;
        }
      }
      p {
        font-size: 2rem;
        color: $color-primary;
        @include respond(phone) {
          color: $color-grey-light-3;
        }
      }
    }

    &__form {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      &__btn {
        display: flex;
        gap: 2rem;
        align-items: center;
        text-align: center;

        &__icon {
          display: flex;
          font-size: 4rem;
          color: $color-primary-dark;
          @include respond(phone) {
            color: $color-grey-light-3;
          }
        }
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color-primary-text-dark, 0.9);
  backdrop-filter: blur(4px);
  z-index: 1000;
  transition: all 0.5s;
}
