.header {
  background-color: $color-grey-light-1;
  border-top-left-radius: $default-border-radius;
  border-top-right-radius: $default-border-radius;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8rem;
  max-width: 100vw;

  &__logo:link,
  &__logo:visited {
    display: inline-block;
    text-decoration: none;
    color: $color-primary-medium;
    transition: all 0.3s;
  }

  &__logo:hover {
    transform: scale(1.05);
    color: $color-primary;
  }
  &__logo:active {
    transform: scale(1.02);
    color: $color-primary-dark;
  }

  &__logo--icon {
    height: 5rem;
    width: 5rem;
    fill: $color-primary;
    // font-size: 4rem;
    margin: 2rem;
    animation: slide-in-top 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  &__nav {
    font-size: 1.8rem;
    font-weight: 500;

    &--list {
      display: flex;
      align-items: center;
      gap: 3rem;
      padding: 2rem;

      li {
        list-style: none;
        a,
        a:link,
        a:visited {
          display: inline-block;
          cursor: pointer;
          padding: 1rem 2rem;
          text-decoration: none;
          color: $color-primary-text-medium;
          background-color: $color-grey-light-2;
          transition: all 0.3s;
          border-radius: 33rem;
        }

        a:hover {
          color: $color-primary-text-dark;
          background-color: $color-primary-light;
          transform: translateY(-3px);
          box-shadow: 0 1rem 2rem rgba($color-primary-text-dark, $alpha: 0.2);
        }
        a:active {
          transform: translateY(-1px);
          box-shadow: 0 2rem 4rem rgba($color-primary-text-dark, $alpha: 0.2);
        }

        &:nth-child(1) {
          animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation-delay: 0s;
        }
        &:nth-child(2) {
          animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation-delay: 0.1s;
        }
        &:nth-child(3) {
          animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation-delay: 0.2s;
        }
        &:nth-child(4) {
          animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation-delay: 0.3s;
        }
      }
    }
  }
}
#header__nav--link--blue:link,
#header__nav--link--blue:visited {
  background-image: linear-gradient(
    to right,
    $color-primary-medium,
    $color-primary
  );
  color: $color-grey-light-1;
}
#header__nav--link--blue:hover,
#header__nav--link--blue:active {
  background-color: $color-primary-light;
  color: $color-primary-text-dark;
}
