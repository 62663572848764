.card {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  backface-visibility: hidden;
  box-shadow: 0 1rem 2rem rgba($color-primary-text-dark, 0.2);
  transition: all 0.5s;

  // @include respond(tab-land) {
  //   height: 100%;
  // }

  &__link:link,
  &__link:active {
    text-decoration: none;
    width: 100%;
    height: 100%;
  }

  &:hover {
    transform: scale(1.05);

    .card__img {
      opacity: 0.8;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: all 0.3s;
  }
}
